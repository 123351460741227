import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Circle from "../components/Circle"
import Figure from "../components/Figure"
import Frame from "../components/Frame"
import { H1, H2, H3, H4, Ul, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj01 from "../imgObjs/imgObj01"
import imgObj02 from "../imgObjs/imgObj02"
import imgObj03 from "../imgObjs/imgObj03"
import imgObj04 from "../imgObjs/imgObj04"
import imgObj05 from "../imgObjs/imgObj05"
import imgObj06 from "../imgObjs/imgObj06"
import imgObj07 from "../imgObjs/imgObj07"

import umschlagVS from "../img/0500/Schreib-und-das-Leben-ist-leicht-UmschlagVS.jpg"
import VeraAnders from "../img/1200/Vera-Anders.jpg"

import breakpoints from "../theme/breakpoints"

const FragenPage = () => (
  <Layout>
    <SEO title="Fragen" keywords={[`Vera Anders`]} />
    <Figure caption="">
      <PictureSet imgObj={imgObj02} />
    </Figure>
    <Section>
      <Circle px={9.5} py={2}>
        <FontAwesomeIcon icon={["fal", "question"]} size="3x" />
      </Circle>
      {/* <H2>Fragezeichen:</H2> */}
      <H1>Antworten</H1>

      <H3>Für wen? </H3>
      <P>
        Das Buch ist für alle gedacht, die schreiben müssen und die schreiben
        wollen. Auch die, die neugierig und offen sind und schneller und besser
        für den Job texten möchten, werden auf ihre Kosten komme. Ich habe es
        auch als Motivation für die verfasst, die wenig oder wirklich ungern
        schreiben.
      </P>
      <H3>Wer? </H3>
      <P>
        <img src={VeraAnders} alt="" />
      </P>
      <P>
        Ich brenne fürs Schreiben und freue mich, wenn der Funke in meinen
        Texten, Trainings, Coaching und jetzt beim Lesen des Handbuchs
        überspringt. Mehr Infos zu mir als selbstständige Germanistin gibt es
        auf{" "}
        <a href="http://textekonzepte.de" target="_blank">
          textekonzepte.de
        </a>{" "}
        und{" "}
        <a href="http://vera-anders-coaching.de" target="_blank">
          vera-anders-coaching.de
        </a>
        .
      </P>
      <H3>Was? </H3>
      <P>
        Die Methoden und Tipps im Buch sammele ich seit über zwanzig Jahren. Sie
        sind einfach und praxiserprobt. Wer im Job textet, sich besser
        organisieren oder mehr über selbst herausfinden will, wird im Buch dazu
        alles Wichtige finden.
      </P>
      <H3>Warum?</H3>
      <P>
        Die Idee, dass wir das Schreiben viel öfter, ganz anders und mit
        interessanten Ergebnissen einsetzen können, ließ mich nicht los, bis ich
        mit diesem Buchprojekt anfing. Mir schwebte eine Sammlung vor, die mir
        bislang fehlte, wenn ich Teilnehmern ein Buch übers Schreiben und nicht
        direkt fünf Titel empfehlen wollte.
      </P>
      <H3>Wie? </H3>
      <P>
        Mein Anspruch an den Stil: unterhaltsam, klar und direkt. Das Buch soll
        sich einladend lesen und Lust aufs Thema wecken. Ich hoffe sehr, dass
        Sie als Leser das auch so empfinden.
      </P>
      <H3>Wo?</H3>
      <P>
        Das Buch habe ich an vielen Orten geschrieben: in meinem Arbeitszimmer
        unterm Dach, in den regelmäßigen Schreibwochen mit meiner guten Freundin
        Geli am Steinhuder Meer und während meiner wöchentlichen Treffen mit
        meiner lieben Freundin Vera in ihrer Waldhütte.
      </P>
      <H3>Wann? </H3>
      <P>
        Von der ersten Zeile bis zum fertigen Buch sind über zwei Jahre
        vergangen. Gerade in der Phase des Setzens, Gestaltens und Lektorierens
        brauchte unser kleines Team aus Setzer, Grafikerin und Korrektorin viel
        Ausdauer, bis alles passte.
      </P>
    </Section>
  </Layout>
)

export default FragenPage
